<template>
  <div>
    <headerSection mainMenu="continue"></headerSection>
    <div class="x-main-content" :style="{ 'height':'100vh','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}"><!--,'background-size':'900px 700px','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})` -->
      <div id="main_content_recommend" class="container">
    <section class="row x-category-index" >
        <div class="col-sm-12 col-lg-12 col-12 recommend_section" style="padding-top:5px;">
            <fieldset class="form-group text-center mb-0 px-1">
                <div class="row mt-2 mb-0 mb-4 justify-content-md-center">
                    <div class="col-sm-12 col-lg-12 col-12" style="padding-top: 40px;">
                        <h2 class="text-center mb-3" style="font-weight: 500;color:#fff;">ฝากต่อเนื่อง</h2>
                        <!--
                        <fieldset class="form-group">
                            <div class="row mt-3 mb-0 justify-content-md-center">
                                    <div class="col-6">
                                        <a href="https://social-plugins.line.me/lineit/share?url=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-line btn-sm btn-block"><img src="https://auto.shib1688.net/assets/images/icon-line.png" style="max-width: 15px;display: inline-block;margin-right: 3px;"> แชร์ </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-facebook btn-sm btn-block"><i class="fa fa-facebook-square mr-1" aria-hidden="true"></i> แชร์ </a>
                                    </div>
                            </div>
                        </fieldset>
                        -->
                        <img src="https://firebasestorage.googleapis.com/v0/b/champber1688.appspot.com/o/images%2F-O3H9gIkzr4_BVO1TxMP.png?alt=media&token=d592d367-3669-4a34-ab5a-d66eacab703f" class="-cover-img img-fluid">
                    </div>
                </div>
            </fieldset>

            <div class="text-title text-center" style="font-family: Kanit;">ฝากต่อเนื่อง</div>
            <div class="row no-gutters">
                <div class="col-6" >
                    <div class="card-report text-center" style="height:60px;display: flex; align-items: center; justify-content: center;">
                        <p class="mb-1" style="font-family: Kanit;">{{ (this.continues && this.continues.promotion)?this.continues.promotion.name:'ฝากต่อเนื่อง 3 วัน' }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center" style="height:60px;">
                        <p class="mb-1" style="font-family: Kanit;">โบนัส</p>
                        <p class="mb-0">{{ numberWithCommas(this.continues.total )}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card-report text-center">
                        <p class="mb-0">
                        <span class="dot" :style="(dots[0])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[1])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[2])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[3])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[4])?`background-color:#04af01`:''"></span>
                        </p>
                        <p class="mb-0">
                        <span class="dot" :style="(dots[5])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[6])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[7])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[8])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[9])?`background-color:#04af01`:''"></span>
                        </p>
                        <p class="mb-0">
                        <span class="dot" :style="(dots[10])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[11])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[12])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[13])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[14])?`background-color:#04af01`:''"></span>
                        </p>
                        <p class="mb-0">
                        <span class="dot" :style="(dots[15])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[16])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[17])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[18])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[19])?`background-color:#04af01`:''"></span>
                        </p>
                        <p class="mb-0">
                        <span class="dot" :style="(dots[20])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[21])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[22])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[23])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[24])?`background-color:#04af01`:''"></span>
                        </p>
                        <p class="mb-0">
                        <span class="dot" :style="(dots[25])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[26])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[27])?`background-color:#04af01`:''"></span>
                        <span class="dot" :style="(dots[28])?`background-color:#04af01`:''"></span>
                        <span class="dot dot2" :style="(dots[29])?`background-color:#04af01`:''"></span>
                        </p>
                    </div>
                </div>
                <div class="col-12 text-center" style="padding-top:22px;">
                    <a href="javascript:void(0)" @click="moveContinuesToWallet()" class="text-white js-account-approve-aware btn" :style="checkStyle()" data-toggle="modal">
                        <div style="font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">โอนเงินเข้ากระเป๋าหลัก</div>
                    </a>
                </div>
                <div class="col-sm-12 col-lg-12 col-12 recommend_section">
                  <p style="color:#fff;text-align: center;padding:30px;font-size: 16px;font-family: Kanit;">
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ 3วัน 7วัน 15วัน 30วัน รับเครดิตฟรีทันที </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ ยอดฝากสะสม 500 บาทขึ้นไป </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ ถอนได้สูงสุด 5 เท่า </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ ทำเทิร์น 2 เท่า </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ ถอนแล้วระบบจะรีเซ็ตเครดิตที่เหลือทั้งหมด </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากครบ 3 วันจะกดได้ในวันถัดไป </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากครบ 7 วันจะกดได้ในวันถัดไป </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากครบ 15 วันจะกดได้ในวันถัดไป </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากครบ 30 วันจะกดได้ในวันถัดไป </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากวันไหนลูกค้าไม่ฝากระบบจะรีเซ็ตนับหนึ่งใหม่ทันที </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ สามารถถอนได้เพียง 5 เท่า หากมีเครดิตมากกว่า ระบบที่ตั้งไว้ ระบบจะดึงเครดิต คืนทันที </b><br>
                      <b class="tt_l tt_full fr_tx1" style="font-size:16px;">✨ หากรับโบนัสแล้วห้ามฝากตังมาเด็ดขาดเล่นโบนัสให้หมดแล้วค่อยฝากมานะคะ ยอดของพี่จะได้ไม่ติดเทิร์นค่ะ </b><br>
                  </p>
              </div>
            </div>
        </div>
        <!-- <div class="col-sm-12 col-lg-12 col-12 recommend_section">
            <p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;">
                <b class="tt_l tt_full fr_tx1" style="font-size:20px;">คืนยอดเล่น รับได้<font color="#FAB537">ทุกวันเวลา 13.00 น.</font> เล่นเสียคืนให้ <font color="#FAB537">5%</font> เล่นได้ได้เพิ่มอีก <font color="#FAB537">1%</font></b>
            </p>
        </div> -->
    </section>
</div>
</div>
<footerSection></footerSection>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment-timezone'
import router from './../router'
const axios = require('axios').default
export default {
  name: 'continues',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  data: function () {
    return {
      continues: [],
      dots: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    moveContinuesToWallet () {
      if (this.continues.total && this.continues.key) {
        this.$store.commit('user/SET_LOADING', true)
        this.$store.dispatch('user/moveCreditToWallet').finally((res) => {
          if (this.user.credit <= 5) {
            axios.get(`https://api.champber1688.com/qcontinuous/continuous/${this.continues.key}`)
            setTimeout(() => {
              this.getContinues()
              this.$store.commit('user/SET_LOADING', false)
            }, 2000)
          } else {
            this.$swal({ icon: 'error', title: 'เงื่อนไข', text: 'ต้องมีเครดิตต่ำกว่า 5 บาท ถึงรับได้ค่ะ' })
            this.$store.commit('user/SET_LOADING', false)
          }
        }).catch(err => { console.error(err) })
      }
    },
    checkStyle () {
      if (this.continues && this.continues.total > 0) {
        return 'background: linear-gradient(180deg,#fbc844,#f88012);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      } else {
        return 'background: linear-gradient(180deg,#cccccc,#cccccc);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      }
    },
    numberWithCommas (x) {
	  if (!x) x = 0
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    async getContinues () {
      const user = this.getUserId()
      if (user) {
        await firebase.firestore().collection('continuous_deposit').where('date', '==', moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
          if (!snapshot.size) {
            this.continues = []
            this.dots = []
          }
          snapshot.forEach((doc) => {
            const data = doc.data()
            data.key = doc.id
            this.continues = data
          })
        })
        // if (this.continues && this.continues.promotion) {
        //   for (let i = 0; i < Number(this.continues.promotion.numday); i++) {
        //     this.dots.push(true)
        //   }
        // } else {
          const fixed = 500
          let last = {}
          await firebase.firestore().collection('continuous_deposit_all').where('userkey', '==', user.key).where('status', '==', true).where('promotionkey','==','ztg0QmfdVt4UzujIR2FG').orderBy('date', 'desc').limit(1).get().then((snapshot) => {
            snapshot.forEach((doc) => {
              const data = doc.data()
              data.key = doc.id
              last = data
            })
          })
          const dateCheck = []
          if (last && last.date) {
            await firebase.firestore().collection('daily_deposit').where('createdate', '>', last.date).where('createdate', '<=', moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).where('userkey', '==', user.key).orderBy('createdate', 'desc').limit(30).get().then((snapshot) => {
              snapshot.forEach((doc) => {
                if (doc.data().total >= fixed) {
                  dateCheck[doc.data().createdate] = true
                }
              })
            })
          } else {
            await firebase.firestore().collection('daily_deposit').where('createdate', '<=', moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).where('userkey', '==', user.key).orderBy('createdate', 'desc').limit(30).get().then((snapshot) => {
              snapshot.forEach((doc) => {
                if (doc.data().total >= fixed) {
                  dateCheck[doc.data().createdate] = true
                }
              })
            })
          }
          if (dateCheck) {
            for (let i = 1; i <= 30; i++) {
              if (dateCheck[moment().tz('Asia/Bangkok').add(-i, 'days').format('YYYY-MM-DD')]) {
                this.dots.push(true)
              } else {
                break
              }
            }
          // }
        }
      }
    }
  },
  mounted () {
    this.scrollToTop()
    this.getContinues()
  }
}
</script>
<style scoped>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.dot2 {
    margin-right: 0px;
}
@media (max-width: 500px) {
    .x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-img-category {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: .8rem;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent {
    display: flex;
}
}
@media (max-width: 990px) {
#main_content_recommend .x-category-index, #main_content_income .x-category-index {
    padding-top: 10px;
}
}

</style>
