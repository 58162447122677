import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import VModal from 'vue-js-modal'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSweetalert2 from 'vue-sweetalert2'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/login.css'
//import '@/assets/css/main.css'
const options = {
  confirmButtonColor: '#FFD74C',
  cancelButtonColor: '#ff7674'
}
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  // set observer to true
  observer: true,
  preLoad: 1.3,
  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})
Vue.use(VueSweetalert2, options)
Vue.use(VModal)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
const configFirebase = {
  apiKey: "AIzaSyB1t9WSXbdn8hnEAKNE2wvv1R1CsXMaSVI",
  authDomain: "champber1688.firebaseapp.com",
  databaseURL: "https://champber1688-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "champber1688",
  storageBucket: "champber1688.appspot.com",
  messagingSenderId: "70624637510",
  appId: "1:70624637510:web:76d07d3334e3249add1047"
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
